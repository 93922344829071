import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import yeslocker from "../../images/new images/longyes.png";
import nolocker from "../../images/new images/longno.png";
import shortyes from "../../images/new images/shortyes.png";
import shortno from "../../images/new images/shortno.png";
import lock from "../../images/Lock.svg";
import Video from "../../images/video.png";
import Swal from "sweetalert2";
import mockup from "../../images/new images/gray.png";
import "sweetalert2/dist/sweetalert2.min.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import DeclineIcon from "../../images/decline-call.png";
import AttendVideoIcon from "../../images/attend-video.png";
import DecVideCall from "../../images/dec-video-call.png";
import MuteSwitch from "../../images/muted_mic_icon.svg";
import UnmuteSwitch from "../../images/unmuted_mic_ico n.svg";
import UnmuteVideo from "../../images/muted_vdo_icon.svg";
import SwtchVideo from "../../images/unmute_vdo_icon.svg";
import ringTone from "../../assets/ringcall.mp3";
import backgroundMusicClick from "../../assets/click.mp3";
import { io } from "socket.io-client";
import Peer from "simple-peer";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const url = process.env.REACT_APP_APP_BACK_URL;
const socketUrl = process.env.REACT_APP_APP_SOCKET_URL;
const socket = io.connect(socketUrl);
const streaming_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
const VADashboard = () => {
  const [isFloating, setIsFloating] = useState(false);
  const [isCallFloating, setIsCallFloating] = useState(false);
  let navigate = useNavigate();
  let { slug } = useParams();
  slug = slug.toLowerCase();
  const myVideo = useRef(null);
  const userVideo = useRef(null);
  let peer = null;
  const [backgroundAudio] = useState(new Audio(ringTone));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [getLocker, setLocker] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocker, setselectedLocker] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [sender, setSender] = useState(null);
  const [stream, setStream] = useState();
  const [userStream, setUserStream] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const connectionRef = useRef(null);
  const [callDeclined, setcallDeclined] = useState(false);
  const [caller, setCaller] = useState("");
  const [name, setName] = useState("");
  const remoteButtonRef = useRef(null);
  const leaveCallButtonRef = useRef(null);
  const ringCall = useRef(null);
  const clickButton = useRef(null);
  const [callerSignal, setCallerSignal] = useState();
  const [callEnded, setCallEnded] = useState(false);
  const [loading, setloading] = useState(false);
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const handleVideoClick = () => {
    const { width } = getWindowDimensions();
    const surfaceTabletWidth = 768;
    if (width < surfaceTabletWidth) {
      setIsFloating(!isFloating);
      setIsCallFloating(false);
    } else {
      setIsFloating(false);
    }
  };
  const handleVideoCallClick = () => {
    const { width } = getWindowDimensions();
    const surfaceTabletWidth = 768;
    if (width < surfaceTabletWidth) {
      setIsCallFloating(!isCallFloating);
      setIsFloating(false);
    } else {
      setIsCallFloating(false);
    }
  };
  useEffect(() => {
    const handleHardRefresh = () => {
      if (performance.navigation.type === 1) {
        if(videoShow){
          leaveCall();
        }
      }
    };

    window.addEventListener('unload', handleHardRefresh);

    return () => {
      window.removeEventListener('unload', handleHardRefresh);
    };
  }, []);
  async function setStreamFun() {
    try {
      const streamObj = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });

      const audioTrack = streamObj.getAudioTracks()[0];
      audioTrack.enabled = true;
      const videoTrack = streamObj.getVideoTracks()[0];
      videoTrack.enabled = true;
      setStream(streamObj);
      if (myVideo.current) {
        myVideo.current.srcObject = streamObj;
      }
      setAudioTrack(audioTrack);
      setVideoTrack(videoTrack);
      socket.on("callUser1", (data) => {
        setloading(true);
        setCaller(data.from);
        setName(data.name);
        setCallerSignal(data.signal);
        const delayInMilliseconds = 1000; // 10 seconds
        const timeoutId = setTimeout(() => {
          if (remoteButtonRef.current) {
            remoteButtonRef.current.click();
          }
          setloading(false);
        }, delayInMilliseconds);
        // Make sure to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
      });
      socket.on("destroyConnection", (data) => {
        destroyConnection();
        console.log('test');
        // stopCamera();
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const destroyConnection = async () => {
    setCallEnded(true);
    setcallDeclined(true);
    setIsOpen(false);
    backgroundAudio.pause();
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;
    const delayInMilliseconds = 1000; // 10 seconds
    const result = await Swal.fire({
      title: "Call Decline?",
      text: "Call declined by user!",
      icon: "warning",
    });
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      if (leaveCallButtonRef.current) {
        leaveCallButtonRef.current.click();
      }
    }, delayInMilliseconds);

    return () => {
      clearTimeout(timeoutId);
    };

  };
  useEffect(() => {
    socket.emit("user_login", "VA" + slug);
    socket.on("userCalling", (data) => {
      setSender(data.slug);
      setIsOpen(true);
      if (ringCall.current) {
        ringCall.current.click();
      }
      // if (clickButton.current) {
      //   clickButton.current.click();
      // }
    });

    siteLockers();
    return () => {
      leaveCall();
    };
  }, []);
  const ring = async () => {
    backgroundAudio.play();
  };
  const clickButtonFun = async () => {
    backgroundAudioClick.play();
  };

  const buttonClick = async () => {
    backgroundAudio.play();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
    script.async = true;
    script.onload = () => {
      /* eslint-disable no-undef */
      loadPlayer({
        url: `${streaming_url}/stream/url/5`, // Replace with your desired channel ID
        canvas: document.getElementById("canvas"),
      });
      /* eslint-enable no-undef */
    };
    document.head.appendChild(script);
    return () => {
      // Clean up the script element if the component unmounts
      document.head.removeChild(script);
    };
  }, [IDBIndex]);
  function stopCamera() {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  }
  // leave video call
  const leaveCall = () => {
    stopCamera();
    socket.emit("destroyCallConnection", {
      caller: slug,
    });
    if (clickButton.current) {
      clickButton.current.click();
    }

    setStream();
    setCallEnded(true);
    setVideoShow(false);
    setCallerSignal();
    setCallAccepted(false);
    // setcallDeclined(true);
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;

    const delayInMilliseconds = 1000; // 10 seconds

    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      setCallEnded(false);
    }, delayInMilliseconds);
  };
  // leaveUserCall
  const leaveUserCall = () => {
    socket.emit("destroyCallConnection", {
      caller: slug,
    });
    if (clickButton.current) {
      clickButton.current.click();
    }
    backgroundAudio.pause();
    setIsOpen(false);
  };
  // leave video call
  const userlLeaveCall = () => {
    stopCamera();
    setStream();
    setCallEnded(false);
    setVideoShow(false);
    setcallDeclined(false);
    setCallAccepted(false);
    setCallerSignal();
    setIsOpen(false);
    backgroundAudio.pause();
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }
    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }
    // Set the userVideo ref to null
    userVideo.current = null;
    // connectionRef.current.destroy();
    connectionRef.current = null;

  };
  // get all site
  const siteLockers = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/lockers/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      if (json.success) {
        setselectedLocker("");
        setIsDisplay(true);
        setLocker(json.lockers);
        console.log(json.lockers[0]._id);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // open locker
  const openLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    setIsLoading(true);
    const response = await fetch(`${url}/api/sites/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });

    setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
    } else {
      toast.error(json.error);
    }
    setIsLoading(false);
    siteLockers();
  };
  //   modal functions
  const openModal = (id) => {
    setIsModalOpen(true);
    setselectedLocker(id);
  };
  // set id
  const displayOpen = async (id) => {
    const result = await Swal.fire({
      title: "Do you want to open this locker?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      openLocker(id);
      // }
    }
  };
  useEffect(() => {
    return () => {
      // Clean up: Stop the background music when the component unmounts
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    };
  }, [backgroundAudio]);
  const displayOpen1 = async (id) => {
    const result = await Swal.fire({
      title: "Do you want to open this locker?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setselectedLocker(id);
      // if (selectedLocker === id) {
      openModal(id);
      // }
    }
  };

  const closeModal = () => {
    setselectedLocker("");
    setIsModalOpen(false);
  };
  // return to available locker
  const returnLocker = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/sites/changeToAvailable/${selectedLocker}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );

    const json = await response.json();
    if (json.success) {
      toast.success(json.message);
      siteLockers();
      setIsLoading(false);
      setIsModalOpen(false);
    } else {
      toast.error(json.error);
    }
  };
  function closeeModal() {
    setIsOpen(false);
  }
  const attendVideoCall = () => {
    backgroundAudio.pause();
    if (clickButton.current) {
      clickButton.current.click();
    }
    setStreamFun();
    socket.emit("recieverOnline", { caller: slug });
    setVideoShow(true);
    setIsOpen(false);
    // setStreamFun();
  };

  const answerCall = (slug) => {
    setCallEnded(false);
    if (peer && !peer.destroyed) {
      peer.destroy();
    }

    peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: slug });
    });
    peer.on("stream", (strme) => {
      setUserStream(strme);
      if (userVideo.current) {
        setCallAccepted(true);
        // Set the srcObject of the video element
        userVideo.current.srcObject = strme;
      }
    });
    peer.signal(callerSignal);
    connectionRef.current = peer;
    socket.on("callEnded", () => {
      // Call ended, clean up
      peer.destroy();
      setStream(null);
    });
    localStorage.setItem("streamDone", true);
  };

  const toggleVideoMute = () => {
    if (clickButton.current) {
      clickButton.current.click();
    }

    if (videoTrack) {
      videoTrack.enabled = !isVideoMuted;
      setIsVideoMuted(!isVideoMuted);
    }
  };

  const toggleAudioMute = () => {
    if (clickButton.current) {
      clickButton.current.click();
    }

    if (audioTrack) {
      audioTrack.enabled = !isAudioMuted;
      setIsAudioMuted(!isAudioMuted);
    }
  };

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <div className="container-fluid text-center ">
            <div className="">
              <div className="form-box card-box mt-3">
                <div className="row">
                  <div className="col-sm-9 locker-side">
                    <div className="row gap locker-row mt-3 overflow-auto">
                      {getLocker[0] && (
                        <>
                          <div className="col-1"></div>
                          <div
                            className={`col-2  ${isLoading && selectedLocker !== getLocker[0]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <div className="lockers">
                              <span className="dot">{1}</span>
                              {getLocker[0].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[0]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[0]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[0]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[0]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {getLocker[3] && (
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[3]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers">
                            <span className="dot">{4}</span>

                            {getLocker[3].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[3]._id && (
                                  <div className="locker-button-text-VA">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[3]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[3]._id && (
                                  <div className="locker-button-text-VA">
                                    Close
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() => displayOpen1(getLocker[3]._id)}
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-2 ">
                        <div className="lockers">
                          {getLocker[6] && (
                            <div
                              className={`lockers-short ${isLoading && selectedLocker !== getLocker[6]._id
                                ? "loading-disabled"
                                : ""
                                }`}
                            >
                              <span className="dot1">{7}</span>
                              {getLocker[6].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[6]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[6]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[6]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[6]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                          {getLocker[7] && (
                            <div
                              className={`lockers-short half-locker ${isLoading && selectedLocker !== getLocker[7]._id
                                ? "loading-disabled"
                                : ""
                                }`}
                            >
                              <span className="dot2">{getLocker[7].relay}</span>
                              {getLocker[7].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[7]._id && (
                                    <div className="locker-button-text-VA locker-button-text-small">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[7]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[7]._id && (
                                    <div className="locker-button-text-VA locker-button-text-small">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[7]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {getLocker[10] && (
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[10]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers">
                            <span className="dot">{11}</span>
                            {getLocker[10].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[10]._id && (
                                  <div className="locker-button-text-VA">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[10]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[10]._id && (
                                  <div className="locker-button-text-VA">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() =>
                                    displayOpen1(getLocker[10]._id)
                                  }
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {getLocker[13] && (
                        <>
                          <div
                            className={`col-2  ${isLoading && selectedLocker !== getLocker[13]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <div className="lockers">
                              <span className="dot">{14}</span>
                              {getLocker[13].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[13]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[13]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[13]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[13]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-1 "></div>
                        </>
                      )}
                    </div>
                    <div className="row locker-row mt-3">
                      {getLocker[1] && (
                        <>
                          <div className="col-1 "></div>
                          <div
                            className={`col-2  ${isLoading && selectedLocker !== getLocker[1]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <div className="lockers">
                              <span className="dot">{2}</span>
                              {getLocker[1].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[1]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[1]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[1]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[1]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {getLocker[4] && (
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[4]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers">
                            <span className="dot">{5}</span>
                            {getLocker[4].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[4]._id && (
                                  <div className="locker-button-text-VA">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[4]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[4]._id && (
                                  <div className="locker-button-text-VA">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() => displayOpen1(getLocker[4]._id)}
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-2 ">
                        <div className="lockers">
                          <img src={mockup} alt="yes" />
                        </div>
                      </div>
                      {getLocker[11] && (
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[11]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers">
                            <span className="dot">{12}</span>
                            {getLocker[11].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[11]._id && (
                                  <div className="locker-button-text-VA">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[11]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[11]._id && (
                                  <div className="locker-button-text-VA">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() =>
                                    displayOpen1(getLocker[11]._id)
                                  }
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {getLocker[14] && (
                        <>
                          <div
                            className={`col-2  ${isLoading && selectedLocker !== getLocker[14]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <div className="lockers">
                              <span className="dot">{15}</span>
                              {getLocker[14].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[14]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[14]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[14]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[14]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-1"></div>
                        </>
                      )}
                    </div>

                    <div className="row locker-row mt-3">
                      {getLocker[2] && (
                        <>
                          <div className="col-1 "></div>
                          <div
                            className={`col-2  ${isLoading && selectedLocker !== getLocker[2]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <div className="lockers">
                              <span className="dot">{3}</span>
                              {getLocker[2].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[2]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[2]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[2]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[2]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {getLocker[5] && (
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[5]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers">
                            <span className="dot">{6}</span>
                            {getLocker[5].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[5]._id && (
                                  <div className="locker-button-text-VA">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[5]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[5]._id && (
                                  <div className="locker-button-text-VA">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() => displayOpen1(getLocker[5]._id)}
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-2 ">
                        <div className="lockers">
                          {getLocker[8] && (
                            <div
                              className={`lockers-short ${isLoading && selectedLocker !== getLocker[8]._id
                                ? "loading-disabled"
                                : ""
                                }`}
                            >
                              <span className="dot1">{9}</span>
                              {getLocker[8].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[8]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[8]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[8]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[8]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                          {getLocker[9] && (
                            <div
                              className={`lockers-short half-locker ${isLoading && selectedLocker !== getLocker[9]._id
                                ? "loading-disabled"
                                : ""
                                }`}
                            >
                              <span className="dot2">{10}</span>
                              {getLocker[9].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[9]._id && (
                                    <div className="locker-button-text-VA locker-button-text-small">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={shortyes}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[9]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[9]._id && (
                                    <div className="locker-button-text-VA locker-button-text-small">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={shortno}
                                    onClick={() =>
                                      displayOpen1(getLocker[9]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {getLocker[12] && (
                        <div
                          className={`col-2  ${isLoading && selectedLocker !== getLocker[12]._id
                            ? "loading-disabled"
                            : ""
                            }`}
                        >
                          <div className="lockers">
                            <span className="dot">{13}</span>
                            {getLocker[12].status === "available" ? (
                              <>
                                {selectedLocker === getLocker[12]._id && (
                                  <div className="locker-button-text-VA">
                                    OPEN
                                  </div>
                                )}
                                <img
                                  src={yeslocker}
                                  alt="yes"
                                  onClick={() => displayOpen(getLocker[12]._id)}
                                />
                              </>
                            ) : (
                              <>
                                {selectedLocker === getLocker[12]._id && (
                                  <div className="locker-button-text-VA">
                                    CLOSE
                                  </div>
                                )}
                                <img
                                  src={nolocker}
                                  onClick={() =>
                                    displayOpen1(getLocker[12]._id)
                                  }
                                  alt="yes"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {getLocker[15] && (
                        <>
                          <div
                            className={`col-2  ${isLoading && selectedLocker !== getLocker[15]._id
                              ? "loading-disabled"
                              : ""
                              }`}
                          >
                            <div className="lockers">
                              <span className="dot">{16}</span>
                              {getLocker[15].status === "available" ? (
                                <>
                                  {selectedLocker === getLocker[15]._id && (
                                    <div className="locker-button-text-VA">
                                      OPEN
                                    </div>
                                  )}
                                  <img
                                    src={yeslocker}
                                    alt="yes"
                                    onClick={() =>
                                      displayOpen(getLocker[15]._id)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {selectedLocker === getLocker[15]._id && (
                                    <div className="locker-button-text-VA">
                                      CLOSE
                                    </div>
                                  )}
                                  <img
                                    src={nolocker}
                                    onClick={() =>
                                      displayOpen1(getLocker[15]._id)
                                    }
                                    alt="yes"
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-1 "></div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-3 call-side mt-3 ">
                    <div className="d-flex gap-2 justify-content-center align-items-center flex-lg-row flex-column ">
                      <img
                        src={Video}
                        alt="video"
                        className="w-100 w-lg-50"
                        style={{ cursor: "pointer", maxWidth: "80px" }}
                      />
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#012269",
                        }}
                      >
                        Video <br />
                        Attendant
                      </span>
                    </div>
                    <div className="h-40">
                      {stream && videoShow && (
                        <>
                          <div
                            className=" w-sm-25 col-sm-12 mt-5 rounded-1 d-flex justify-content-center align-items-center flex-column "
                            // style={{
                            //   background: "linear-gradient(#323232, #4c4c4c)",
                            //   position: "relative",
                            // }}
                            style={{ background: "linear-gradient(#323232, #4c4c4c)", width: isCallFloating ? '300px' : '100%', position: isCallFloating ? 'fixed' : 'relative', bottom: '10px', right: isCallFloating ? '10px' : '0', zIndex: "99999" }}
                            onClick={handleVideoCallClick}
                          >
                            {!callAccepted &&
                              <div class="lds-spinner" style={{ top: "60px", left: "-10px" }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            }
                            <ReactPlayer
                              playing
                              muted
                              style={{
                                position: "absolute",
                                top: "3%",
                                right: "2%",
                                zIndex: "99999",
                                objectFit: "cover",
                              }}
                              height="25%"
                              width="30%"
                              url={stream}
                            />
                            {!callEnded ? (
                              <video
                                style={{
                                  position: "relative",
                                }}
                                height="100%"
                                width="100%"
                                ref={userVideo}
                                autoPlay
                                playsInline
                              />
                            ) : (
                              <video autoPlay playsInline
                              />
                            )}
                            {/* {!callAccepted && <h5>Call Connecting ...</h5>} */}
                            {callDeclined && <h5>Call declined by User...</h5>}
                          </div>
                        </>
                      )}
                      {videoShow && !callEnded && (
                        <>
                          <div

                            className="icons-wrap-end-call mt-3"

                          >
                            <div
                              className="icon-call caller-icon d-flex justify-content-center align-items-center"
                              style={{
                                background: "rgb(2, 1, 93)",
                                width: "35px",
                                height: "35px",
                              }}
                              onClick={toggleVideoMute}
                            >
                              {isVideoMuted ? (
                                <img
                                  src={SwtchVideo}
                                  alt="Unmute Video"
                                  className="mx-2"
                                  height={27}
                                  width={27}
                                />
                              ) : (
                                <img
                                  src={UnmuteVideo}
                                  alt="Mute Video"
                                  className="mx-2 d-flex justify-content-center rounded"
                                  height={27}
                                  width={27}
                                />
                              )}
                            </div>

                            <Link onClick={leaveCall}>
                              <div className="icon-call dec-call-video">
                                <img src={DecVideCall} alt="" />
                              </div>
                            </Link>
                            <div
                              className="icon-call d-flex  align-items-center"
                              style={{
                                background: "rgb(2, 1, 93)",
                                width: "35px",
                                height: "35px",
                              }}
                              onClick={toggleAudioMute}
                            >
                              {isAudioMuted ? (
                                <img
                                  src={UnmuteSwitch}
                                  alt=""
                                  className="mx-2"
                                  width={27}
                                  height={27}
                                />
                              ) : (
                                <img
                                  src={MuteSwitch}
                                  alt=""
                                  className="mx-2 rounded"
                                  width={27}
                                  height={27}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="h-50">
                      <div
                        className=" w-sm-25 col-sm-12 mt-5 rounded-1 d-flex justify-content-center align-items-center flex-column "
                        style={{
                          position: "relative",
                          overflow: "auto",
                          objectFit: "contain",
                        }}
                      >
                        <canvas
                          id="canvas"
                          height="200"
                          width="100%"
                          style={{ width: isFloating ? '300px' : '100%', position: isFloating ? 'fixed' : 'static', bottom: '10px', right: '10px', zIndex: isFloating ? "99999" : '0' }}
                          onClick={handleVideoClick}
                        ></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modal-content"
        style={{
          overlay: {
            backgroundColor: "rgb(43 41 41 / 75%)", // Update the background color here
          },
          content: {
            backgroundColor: "white", // Update the modal content background color here
          },
        }}
        shouldCloseOnOverlayClick={false}
      >
        <img src={lock} alt="lock" className="modal-image" />
        <p className="modal-text mt-3">Do you want to release this locker?</p>
        <div className="modal-button">
          <button className="modal-btn1" onClick={returnLocker}>
            Yes
          </button>
          <button className="modal-btn2" onClick={closeModal}>
            No
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onClick={closeeModal}
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div
          className="p-5 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <h5
            className="text-center text-dark  "
            style={{
              fontFamily: "Arial",
              fontSize: "25px",
              fontWeight: "500",
            }}
          >
            Inviting you for a video chat
          </h5>
          <div className="d-flex justify-content-center align-items-center flex-row mt-5">
            <div className="icon-dec" onClick={leaveUserCall}>
              <img src={DeclineIcon} alt="" />
            </div>
            <div className="icon-att-vid" onClick={attendVideoCall}>
              <img src={AttendVideoIcon} alt="" />
            </div>
          </div>
        </div>
      </Modal>
      <a ref={remoteButtonRef} onClick={() => answerCall(slug)}></a>
      <a ref={leaveCallButtonRef} onClick={userlLeaveCall}></a>
      <a ref={ringCall} onClick={ring}></a>
      <a ref={clickButton} onClick={clickButtonFun}></a>
    </div>
  );
};

export default VADashboard;
